<template>
  <div class="airConditionerSwitch">
    <div class="tips" @click="isShowTipsDialog = !isShowTipsDialog">
      <img src="./img/tips.png" alt="" />
    </div>
    <div class="switch" @click="handelChangeSwitch">
      <img v-if="detailData.switch == '开机'" src="./img/switch.png" alt="" />
      <img v-else src="./img/close.png" alt="" />
    </div>
    <!-- 进度条 -->
    <div class="progress-bar" id="progress">
      <canvas id="canvas"></canvas>
      <div class="info">
        <div class="pattern">{{ detailData.mode }}</div>
        <div class="temperature">
          <span>{{ detailData.settingtemperature }}</span>
          <span>°C</span>
        </div>
        <div class="wind-speed">
          {{ detailData.switch == "开机" ? "运行中" : "已关机" }}
        </div>
      </div>
      <!-- 温度 -->
      <div
        :class="['item', `item_${index}`]"
        v-for="(item, index) in number"
        :key="index"
      >
        <div>
          {{ item }}
        </div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="content">
      <!-- 控件 -->
      <div class="control">
        <div class="title temperature">
          <div class="label">温度</div>
          <div class="line"></div>
          <div class="value">{{ detailData.settingtemperature }}°C</div>
        </div>
        <div class="temperature-control">
          <div
            class="left"
            :style="{
              color: detailData.settingtemperature > 16 ? '#FFFFFF' : '#fe4416',
            }"
          >
            16
          </div>
          <div
            class="right"
            :style="{
              color:
                detailData.settingtemperature == 31 ? '#FFFFFF' : '#fe4416',
            }"
          >
            31
          </div>
          <van-slider
            @change="handelChangeTemperature"
            active-color="#FE4416"
            inactive-color="#F2E0E0"
            v-model="detailData.settingtemperature"
            bar-height="34"
            :min="16"
            :max="31"
          />
        </div>
        <div class="title temperature">
          <div class="label">风速</div>
          <div class="line"></div>
          <div class="value">{{ detailData.windValue | formatWind }}</div>
        </div>
        <div class="temperature-control wind-control">
          <div
            class="left"
            :style="{
              color: detailData.windValue > 1 ? '#FFFFFF' : '#FFA200',
            }"
          >
            低速
          </div>
          <div
            class="right"
            :style="{
              color: detailData.windValue == 4 ? '#FFFFFF' : '#FFA200',
            }"
          >
            自动
          </div>
          <van-slider
            @change="handelChangeWind"
            active-color="#FFBC47"
            inactive-color="#FAF2E7 "
            v-model="detailData.windValue"
            bar-height="34"
            :min="1"
            :max="4"
          />
        </div>
      </div>
      <!-- 模式 -->
      <div class="pattern">
        <div class="type">
          <div class="label">模式</div>
          <div class="line"></div>
          <div class="value">{{ detailData.mode }}</div>
        </div>
        <div class="item">
          <div
            v-for="(item, index) in modelList"
            :key="index"
            @click="handelChangeModel(item)"
          >
            <img
              :src="detailData.mode == item.label ? item.activeIcon : item.icon"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      class="my-dialog"
      v-model="isShowTipsDialog"
      :showConfirmButton="false"
      :showCancelButton="false"
      :closeOnClickOverlay="true"
    >
      <div class="header">
        <img src="./img/tips-header.png" alt="" />
      </div>
      <div class="content-tips">
        <div class="title">空调使用说明</div>
        <div class="explain">
          <div class="text">
            空调控制界面共有五个操作按键，左侧从上至下依次为电源（&nbsp;<img
              src="./img/tips-swicth.png"
              alt=""
            />&nbsp;）、风速（&nbsp;<img
              src="./img/tips-wind.png"
              alt=""
            />&nbsp;）、温度（&nbsp;<img
              src="./img/temp.png"
              alt=""
            />&nbsp;）、模式-制冷（&nbsp;<img
              src="./img/tips-cold.png"
              alt=""
            />&nbsp;）制热（&nbsp;<img
              src="./img/tips-hot.png"
              alt=""
            />&nbsp;）送风（&nbsp;<img
              src="./img/tisp-wind.png"
              alt=""
            />&nbsp;）。
          </div>
        </div>
        <div class="control-text">
          <div class="first">
            <div class="text">
              按右侧电源
              <img src="./img/first-close.png" alt="" /> 为开机，再按一次为关机
            </div>
            <div class="line"></div>
          </div>
          <div class="second">
            <div class="text">
              在开机状态下按
              <img src="./img/slide.png" alt="" /> 左右滑动选择合适的温度
            </div>
            <div class="line"></div>
          </div>
          <div class="third">
            <div class="item-t">
              在开机状态下按
              <img src="./img/slide.png" alt="" /> 左右滑动选择合适的风速
            </div>
            <div class="item-b">
              风速分为：<img src="./img/icon-1.png" alt="" /> （高）<img
                src="./img/icon-2.png"
                alt=""
              />
              （中）<img src="./img/icon-3.png" alt="" /> （低）<img
                src="./img/icon-4.png"
                alt=""
              />
              （自动），在“自动”模式下，风速自动换挡。即当室温与设置温度相差
              ≤3°C时，低风速；3°C≤温差≤5°C,中风速；温差>5°C，高风速。
            </div>
            <div class="line"></div>
          </div>
          <div class="four">
            <div class="text">
              在开机状态下按模式
              <img src="./img/icon-5.png" alt="" /> 为制冷；按
              <img src="./img/icon-6.png" alt="" /> 为制热；按
              <img src="./img/icon-7.png" alt="" /> 为送风。
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="btn" @click="isShowTipsDialog = false">我知道了</div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "@/utils/utils";
import { getEquipmentDetail, updateControlEquipment } from "./api.js";
import Vue from "vue";
import { Slider } from "vant";

Vue.use(Slider);
import circleProgress from "./canvas.js";
export default {
  data() {
    return {
      newCode: "",
      isShowTipsDialog: false,
      modelList: [
        {
          label: "制冷",
          value: 3,
          icon: require("./img/cold-gray.png"),
          activeIcon: require("./img/cold.png"),
        },
        {
          label: "制热",
          value: 1,
          icon: require("./img/heating-gray.png"),
          activeIcon: require("./img/heating.png"),
        },
        {
          label: "通风",
          value: 9,
          icon: require("./img/wind-gray.png"),
          activeIcon: require("./img/wind.png"),
        },
      ],
      number: [16, 18, 20, 22, 24, 26, 28, 30, 31],
      canvasParams: {
        el: "#canvas",
        number: 16,
        canvasWidth: "",
        canvasHeight: "",
      },
      detailData: {},
      id: "",
      timer: null,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.resizeCanvas);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas);
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  async mounted() {
    const { id, code } = this.$route.query;
    if (id != undefined) {
      this.id = id;
    }
    if (code != undefined) {
      this.newCode = code;
    }
    await this.getEquipmentDetail(id);
  },
  filters: {
    formatWind(value) {
      const obj = {
        1: "低速",
        2: "中速",
        3: "高速",
        4: "自动",
      };
      return obj[value];
    },
  },
  computed: {
    code() {
      return this.$store.state.code;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    //控制
    updateControlEquipment(val, tagCode) {
      const params = {
        deviceId: this.id,
        val,
        tagCode,
      };
      let url = `https://cgzc-dev.zhuneng.cn/smartdeviceproxy/admin/huacong/control`;
      if (window.location.origin.indexOf("https://yshgjyd.nbhq.gov.cn") != -1) {
        url = `https://yshgjyd.nbhq.gov.cn/jieneng/smartdeviceproxy/admin/huacong/control`;
      }
      this.$axios
        .get(url, {
          params,
          headers: {
            code: this.code || this.newCode,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            this.timer = setTimeout(() => {
              this.getEquipmentDetail(this.id, this.code);
            }, 4000);
          }
        });
    },
    //开关空调
    handelChangeSwitch() {
      this.detailData.switch == "开机"
        ? (this.detailData.switch = "关机")
        : (this.detailData.switch = "开机");
      const val = this.detailData.switch == "开机" ? 1 : 0;
      this.updateControlEquipment(val, "switch");
    },
    //切换模式
    handelChangeModel(item) {
      this.detailData.mode = item.label;
      this.updateControlEquipment(item.value, "mode");
    },
    resizeCanvas() {
      new circleProgress(this.canvasParams);
    },
    //获取空调详情信息
    getEquipmentDetail(id) {
      let url = `https://cgzc-dev.zhuneng.cn/smartdeviceproxy/admin/huacong/control/${id}`;
      if (window.location.origin.indexOf("https://yshgjyd.nbhq.gov.cn") != -1) {
        url = `https://yshgjyd.nbhq.gov.cn/jieneng/smartdeviceproxy/admin/huacong/control/${id}`;
      }
      this.$axios
        .get(url, {
          headers: {
            code: this.code || this.newCode,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            const { data } = res;
            data.valueList.forEach((item) => {
              data[item.tagCode] = item.val;
            });
            this.detailData = data;
            if (this.detailData.settingtemperature) {
              this.detailData.settingtemperature = Number(
                this.detailData.settingtemperature
              );
            }
            const obj = {
              低速: 1,
              中速: 2,
              高速: 3,
              自动: 4,
            };
            this.detailData.windValue = obj[this.detailData.settingwind];
            this.canvasParams.number = this.detailData.settingtemperature;
            new circleProgress(this.canvasParams);
          }
        });
    },
    handelChangeTemperature(value) {
      debounce(() => {
        this.canvasParams.number = value;
        this.updateControlEquipment(value, "settingtemperature");
        new circleProgress(this.canvasParams);
      }, 300)();
    },
    handelChangeWind(value) {
      debounce(() => {
        this.detailData.windValue = value;
        this.updateControlEquipment(value, "settingwind");
        this.$forceUpdate();
      }, 100)();
    },
  },
};
</script>
<style lang="less" scoped>
.airConditionerSwitch {
  width: 100%;
  min-height: 100vh;
  background: #f5eeec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  .my-dialog {
    ::v-deep .van-dialog {
      top: 45%;
      width: 654px;
      background-color: transparent;
    }
    .van-dialog__content {
      .header {
        width: 100%;
        height: 380px;
        // background-image: url("./img/tips-header.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-tips {
        padding-bottom: 60px;
        box-sizing: border-box;
        position: relative;
        top: -2px;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        .title {
          text-align: center;
          font-size: 48px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #32435a;
          line-height: 66px;
          margin-bottom: 12px;
        }
        .explain {
          width: 100%;
          padding: 0 22px 0 24px;
          box-sizing: border-box;
          .text {
            border-radius: 8px;
            padding: 8px 26px 8px 26px;
            box-sizing: border-box;
            width: 100%;
            background: rgba(254, 68, 22, 0.06);
            font-size: 24px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 42px;
            img {
              width: 28px;
              height: 28px;
              vertical-align: middle;
            }
          }
        }
        .control-text {
          padding: 35px 40px 0 40px;
          box-sizing: border-box;
          .first,
          .second,
          .third,
          .four {
            font-size: 28px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            .line {
              margin: 30px auto 30px auto;
              // margin-top: 30px;
              // margin-bottom: 30px;
              width: 468px;
              height: 2px;
              background-image: linear-gradient(
                to right,
                #e02020 0%,
                #e02020 50%,
                transparent 50%
              );
              opacity: 0.2;
              background-size: 20px 1px;
              background-repeat: repeat-x;
            }
            img {
              width: 40px;
              height: 40px;
              vertical-align: middle;
            }
          }
          .first {
            text-align: center;
          }

          .second {
            img {
              width: 44px;
              height: 44px;
            }
          }
          .third {
            .item-b {
              vertical-align: middle;
              margin-top: 4px;
              box-sizing: border-box;
              text-align: left;
              font-size: 24px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 34px;
              img {
                vertical-align: middle;
                &:first-child {
                  width: 48px;
                  height: 16px;
                }
                &:nth-child(2) {
                  width: 52px;
                  height: 16px;
                }
                &:nth-child(3) {
                  width: 48px;
                  height: 16px;
                }
                &:nth-child(4) {
                  width: 48px;
                  height: 16px;
                }
              }
            }
            img {
              width: 44px;
              height: 44px;
            }
          }
          .four {
            .text {
              text-align: left;
            }
          }
        }
        .footer {
          margin-top: 38px;
          padding: 0 48px 0 48px;
          box-sizing: border-box;
          .btn {
            padding: 20px 0;
            box-sizing: border-box;
            text-align: center;
            background: #fe4416;
            border-radius: 58px;
            width: 100%;
            font-size: 32px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 56px;
          }
        }
      }
    }
  }
  .switch {
    z-index: 12;
    position: fixed;
    top: 46px;
    right: 0;
    width: 166px;
    height: 166px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tips {
    z-index: 12;
    position: fixed;
    top: 46px;
    left: 16px;
    width: 132px;
    height: 132px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .progress-bar {
    width: 508px;
    height: 508px;
    position: relative;
    margin-top: 170px;
    box-sizing: border-box;
    // border: 1px solid #000;
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 392px;
      height: 392px;
      background: linear-gradient(180deg, #f9f4f4 1%, #f4efed 100%);
      box-shadow: 6px 14px 38px 0px #e0b5b5;
      transform: translate(-50%, -50%);
      .pattern {
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 50px;
      }
      .temperature {
        display: flex;
        align-items: flex-start;
        span {
          font-size: 140px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 196px;
          &:last-child {
            font-size: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 56px;
          }
        }
      }
      .wind-speed {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #f4a6a6;
        width: 137px;
        height: 50px;
        border-radius: 50px;
        border: 2px solid #f4a6a6;
        box-sizing: border-box;
      }
    }
    .item {
      position: absolute;
      left: 0;
      top: -10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #f4a6a6;
      line-height: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .dot {
        margin-top: 12px;
        width: 10px;
        height: 10px;
        background: #e9aaaa;
        border-radius: 50%;
      }
      &.item_0 {
        left: 0;
        top: 83%;
        transform: rotate(234deg);
      }
      &.item_1 {
        left: -12%;
        top: 50%;
        transform: rotate(268deg);
      }
      &.item_2 {
        left: -5%;
        top: 12%;
        transform: rotate(312deg);
      }
      &.item_3 {
        left: 20%;
        top: -10%;
        transform: rotate(340deg);
      }
      &.item_4 {
        top: -15%;
        left: 59%;
        transform: rotate(18deg);
      }
      &.item_5 {
        left: 89%;
        top: 0%;
        transform: rotate(45deg);
      }
      &.item_6 {
        left: 107%;
        top: 31%;
        transform: rotate(74deg);
      }
      &.item_7 {
        left: 105%;
        top: 65%;
        transform: rotate(108deg);
      }
      &.item_8 {
        left: 92%;
        top: 83%;
        transform: rotate(134deg);
      }
    }
  }
  .content {
    margin-top: 102px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    .control {
      margin-bottom: 24px;
      width: 100%;
      padding: 46px 38px 46px 38px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      .title {
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .label,
        .value {
          position: relative;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 42px;
        }
        .value {
          color: #ff2323;
        }

        .line {
          margin: 0 15px;
          width: 3px;
          height: 34px;
          background-color: #000;
        }
      }
      .temperature-control {
        padding: 8px 12px;
        box-sizing: border-box;
        height: 50px;
        background: linear-gradient(180deg, #e8e8e8 0%, #f7f7f7 100%);
        border-radius: 29px;
        margin-bottom: 42px;
        position: relative;
        &.wind-control {
          margin-bottom: 0;
        }
        .left {
          z-index: 8;
          position: absolute;
          left: 8%;
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 34px;
        }
        .right {
          z-index: 8;
          position: absolute;
          right: 8%;
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 34px;
        }
        .van-slider {
          height: 100% !important;
          // .van-slider__button-wrapper {
          //   right: 3%;
          // }
          .van-slider__button {
            width: 42px;
            height: 42px;
            background: #ffffff;
            box-shadow: 0px 4px 8px 0px rgba(227, 231, 241, 0.5);
          }
        }
      }
    }
    .pattern {
      box-sizing: border-box;
      padding: 32px 38px 54px 38px;
      background: #ffffff;
      border-radius: 16px;
      .type {
        display: flex;
        align-items: center;
        .label {
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 42px;
        }
        .line {
          margin: 0 15px;
          width: 3px;
          height: 34px;
          background-color: #000;
        }
        .value {
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 42px;
        }
      }
      .item {
        box-sizing: border-box;
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 46px;
        flex-wrap: wrap;
        div {
          width: 90px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
